import styled from "styled-components"

const Underlining = styled.span`
  padding: 0.1rem 0rem;
  box-shadow: inset 0 ${({ big }) => (big ? "-0.3rem" : "-.1rem")} 0
    ${({ theme, highlight }) =>
      highlight ? theme.colors.secondary : theme.colors.tertiary};
  transition: box-shadow 0.2s ease-out, color 0.2s ease-out,
    padding 0.2s ease-out;
  &:hover {
    box-shadow: inset 0 ${({ big }) => (big ? "-3rem" : "-2.2rem")} 0
      ${({ theme, highlight }) =>
        highlight ? theme.colors.secondary : theme.colors.tertiary};
    padding: 0.1rem 0.2rem;
  }
  text-shadow: 0px 0px 3px
    ${({ theme }) =>
      theme.colors.light ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)"};
`

export default Underlining
